@import "src/variables";

.pipe::before {
    content: "|";
    margin: 0 0.75em;
}

.symmetricBar {
    padding: $symmetric-bar-padding-y $symmetric-bar-padding-x;
    display: grid;
    grid-template-columns: 1fr auto 1fr;

    > * {
        display: flex;
        justify-content: center;
    }

    > :first-child {
        padding: var(--symmetric-bar--column-left--padding, 0);
        justify-content: flex-start;
    }

    > :last-child {
        padding: var(--symmetric-bar--column-right--padding, 0);
        justify-content: flex-end;
    }
}
