@import "src/variables";

.statusBar {
    width: 100%;
    height: 100%;

    // SVGs are inline elements by default. Styles like font-size and line-height will affect
    // it's height, which is something we don't want.
    display: block;
    background-color: $status-bar-bg-color;

    :global(.ERROR) {
        fill: $status-error-color;
    }

    :global(.WARNING) {
        fill: $status-warning-color;
    }

    :global(.SLOW) {
        fill: $status-slow-color;
    }

    :global(.DEBUG) {
        fill: $status-debug-color;
    }

    :global(.OK) {
        fill: $status-ok-color;
    }

    :global(.PME) {
        fill: $status-pme-color;
    }

    // For some reason visited links sometimes get the default black fill in Chrome, completely
    //  ignoring parent's value.
    a:visited {
        //noinspection CssInvalidPropertyValue
        fill: inherit;
    }
}
