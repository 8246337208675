@import "src/variables";

.textWithTooltip {
    // Force the tooltip text to never be wider than it's content,
    // e.g. if accidentally styled with flex-grow: 1.
    // This is to ensure the correct placement of the popover.
    // It's a safety rule, and parent's styling should be fixed if this causes problems.
    max-width: fit-content !important;

    > .icon {
        margin-left: 0.2rem;
        font-size: smaller;
        width: 1em;
    }
}

.popover {
    &:global(.popover) {
        max-width: 500px;
    }

    .errorText {
        color: $status-error-color;
    }

    .slowText {
        color: $status-slow-color;
    }

    .fullGlossaryLink {
        text-align: right;

        a:after {
            content: " \00BB";
        }
    }
}
