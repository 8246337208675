@import "src/variables";
@import "src/global";

.openSelectorButton {
    margin-left: 1rem;
    margin-right: 1rem;
    // flex does a better job aligning the tooltip icon
    display: inline-flex;
    align-items: center;

    .date {
        font-weight: bold;
    }
}

.customTimeRangeSelector {
    display: flex;
    justify-content: space-evenly;

    // Style the calendar to fit in with our other styles
    :global(.react-datepicker) {
        font-family: "Titillium Web", sans-serif;
        color: $text-color;
        display: flex;

        border: 1px solid $border-color;

        :global(.react-datepicker__header) {
            border-bottom: 1px solid $border-color;

            :global(.react-datepicker__current-month),
            :global(.react-datepicker__day-name) {
                color: $text-color;
            }
        }

        :global(.react-datepicker__time-container) {
            border-left: 1px solid $border-color;

            :global(.react-datepicker-time__header) {
                color: $text-color;
            }
        }

        :global(.react-datepicker__day) {
            color: $text-color;
        }

        :global(.react-datepicker__day--disabled) {
            color: $disabled-color;
        }

        :global(.react-datepicker__day--selected),
        :global(.react-datepicker__day):not(:global(.react-datepicker__day--disabled)):hover {
            @include activeItem($primary);
        }
    }
}

.timeRangeSelector {
    // Without this the calendars overflow the modal when shown on a narrower half screen width
    min-width: 800px;
}

.dateSection {
    margin-bottom: 10px;
}

.fromToArrow {
    margin-left: 10px;
    margin-right: 10px;
}
