@import url("https://fonts.googleapis.com/css?family=Titillium+Web");
@import "variables";

// Bootstrap variable overrides; must be before importing bootstrap.
$body-color: $text-color;
$nav-link-disabled-color: $text-color;
$nav-item-disabled-color: $text-color;
$primary: #4193e3; // Selected backgrounds and link text
$secondary: $text-color;

$link-decoration: none;
$link-hover-decoration: underline;

$caret-width: 0.5em;
$caret-vertical-align: inherit;

$dropdown-link-color: $text-color; // For some reason these just have their own colour; set it back.

$form-select-box-shadow: $box-shadow;
$card-cap-bg: $header-background-color;

$toast-max-width: 500px;

@import "~bootstrap/scss/bootstrap.scss";

// Bootstrap doesn't seem to have a consistent disabled colour, so define one.
$disabled-color: $dropdown-link-disabled-color;

/**
 * Remove focus styles for non-keyboard focus, e.g. a click or tap
 */
:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
}

// Style button and link focus the same.
.btn,
a {
    &:focus {
        outline: 2px solid black;
        box-shadow: none;
    }
}

html {
    height: 100%;
}
html,
body {
    font-family: "Titillium Web", sans-serif;
    font-size: 14px;
    min-height: 100%;
    background-color: $body-background-color;
}

a {
    font-family: "Titillium Web", sans-serif;
}

// Make error pages shorter when they're beneath the header.
#root > embedded-error-page:nth-child(2)::part(screen) {
    height: calc(100vh - 120px);
}

@mixin activeItem($param-background-colour) {
    box-shadow: none;
    background-color: $param-background-colour;
    border-color: $param-background-colour;
    color: $inverted-text-color;

    // tooltip icon
    .fa-inverse {
        color: $param-background-colour;
    }
}

.shadowInsteadOfBorder {
    border: 0;
    box-shadow: $box-shadow;
}

.nav *:not(:last-child) {
    right: 1px;
}

.nav-item {
    box-shadow: $box-shadow;
}

// Hide the default dropdown toggle caret.
.dropdown-toggle::after {
    display: none;
}

.dropdown-item,
.dropdown-toggle {
    &.active,
    &:active,
    &:hover {
        .platformIcon {
            color: $dropdown-link-active-color;
        }

        .v1 {
            &.inverted {
                display: inline;
            }

            &:not(.inverted) {
                display: none;
            }
        }
    }
}

.dropdown-item {
    &.disabled {
        .badge {
            background-color: darkgrey !important;
        }

        .platformIcon {
            color: $disabled-color !important;
        }

        .v1 {
            &.disabled {
                display: inline;
            }

            &:not(.disabled) {
                display: none;
            }
        }
    }
}

@mixin buttonTextOnHover {
    text-shadow: 0.05ex 0.05ex 0;
}

@mixin tribeButtonVariant($param-active-background-colour, $param-border-colour) {
    // Bootstrap has a variable `$btn-box-shadow` but it doesn't seem to do anything.
    box-shadow: $box-shadow;

    background: $btn-border-width solid $param-border-colour;

    &:hover {
        @include buttonTextOnHover;
        box-shadow: $hover-box-shadow;
    }

    &:hover,
    &.active {
        @include activeItem($param-active-background-colour);
    }

    &:focus:not(:focus-visible) {
        box-shadow: $box-shadow;
    }
}

.btn-tribe-primary {
    @include tribeButtonVariant($primary, transparent);
}

.btn-outline-tribe-primary {
    @include tribeButtonVariant($primary, $primary);
    color: $primary;
}

.btn-outline-tribe-secondary {
    @include tribeButtonVariant($text-color, $text-color);
}

.OK {
    background-color: $status-ok-color;
}

.WARNING {
    background-color: $status-warning-color;
}

.SLOW {
    background-color: $status-slow-color;
}

.ERROR {
    background-color: $status-error-color;
}

.PME {
    background-color: $status-pme-color;
}

section.standard-section {
    border: 1px solid $border-color;
    > div:first-child {
        font-size: $font-size-lg;
        font-weight: 700;
        background-color: $section-header-background-color;
        border-bottom: 1px solid $border-color;
        padding: 0.5em;
    }
}
