@import "src/variables";
@import "src/global";

.content {
    margin-left: $page-side-margin;
    margin-right: $page-side-margin;
}

.footer {
    padding: 10px $page-side-margin;
    margin-top: 30px;
    background: $primary;

    &,
    & a {
        color: $inverted-text-color;
    }

    & a {
        font-weight: bold;
    }
}
