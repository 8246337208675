.container {
    display: inline-block;
    width: 25px;
    text-align: center;

    // This element refuses to shrink in any flexbox it finds itself in.
    flex-shrink: 0;

    :global(.platformIcon) {
        margin-right: 5px;
    }

    :global(.v1) {
        margin-top: -3px;
        margin-left: -7px;

        &:global(.inverted),
        &:global(.disabled) {
            display: none;
        }
    }

    .Chrome {
        color: #508ec0;
    }
    .Firefox {
        color: orangered;
    }
    .Android {
        color: #52b42d;
    }
    .iPhone {
        color: #555555; // Official Apple grey
    }
    .iPad {
        color: #555555;
    }
    .App {
        color: #333333;
    }
    .Edge {
        color: #104E92;
    }
}
