.notificationIcon {
    margin-right: 10px;
}

.error {
    color: #d22d05;
}

.info {
    color: #6c757d;
}

.haveNotifications {
    color: black;
}

.noNotifications {
    color: lightgrey;
}

.bellButton {
    cursor: pointer;
}
