$text-color: #444444;
$header-background-color: white;
$body-background-color: #f6f6f6;
$inverted-text-color: white;
$border-color: #dee2e6;
$section-header-background-color: #e1e1e1;
$box-shadow: 0 1px 1px $border-color;
$hover-border-color: #aaaaaa;
$hover-box-shadow: 0 2px 5px $hover-border-color;
$page-side-margin: 20px;
$nav-item-padding: 5px 10px;

$status-ok-color: #008000; // aka green
$status-error-color: #ff0000; // aka red
$status-warning-color: #ffd700;
$status-slow-color: #800080;
$status-debug-color: #6699ff;
$status-pme-color: #d3d3d3;

// Components
$symmetric-bar-padding-x: 0;
$symmetric-bar-padding-y: 5px;

$status-bar-bg-color: white;
