@import "src/variables";
@import "src/global";

$user-journey-row-height: 30px;

.settingsBar {
    // Toggle menu button is intentionally hidden underneath the settings bar toggle button, so it looks like the latter
    // fulfills both roles.
    --symmetric-bar--column-right--padding: 0;

    // Stretch the background to the screen edges, but maintain the content padding.
    background-color: white;
    margin-left: -$page-side-margin;
    margin-right: -$page-side-margin;
    padding-left: $page-side-margin;
    padding-right: $page-side-margin;

    .legend {
        display: flex;
        gap: 2em;

        > div {
            display: flex;
            align-items: center;

            .colourBlock {
                display: inline-block;
                width: 1em;
                height: 1em;
                margin-right: 0.5em;
            }
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 2ex;
    padding-top: 0.5ex;

    .bars {
        $row-gap: 0.5em;
        $column-gap: 0.5em;
        $ticks-row-background: $body-background-color;

        @mixin barColumn {
            // Fake column gap.
            padding-left: $column-gap;
            padding-right: $column-gap;
        }

        // We want children of those elements to be laid out in a grid not the elements themselves.
        @mixin rowContainer {
            display: contents;
        }

        display: grid;
        // The minimum size of the [start] column is controlled by the width of the left ticks filler.
        // I have not found a way to specify it using available CSS utilities in the grid definition.
        grid-template-columns:
            [start] fit-content(250px)
            [bar-start] 1fr [bar-end]
            6ch [end];
        // Can't have column gap with the current solution for highlighting rows on hover.
        gap: 0;

        .columnFiller {
            display: block;
            width: 100%;
            height: 100%;
        }

        .ticksRow {
            @include rowContainer();

            // Fix a bug in Chrome where 1px of element underneath is visible around the sticky element's background.
            // We don't care about bottom 1px.
            :global(.sticky-top) {
                $border: 1px solid $ticks-row-background;
                box-sizing: content-box;
                // This is required to fix the top 1px.
                top: -1px;
                // Adds back the 1px of height we just lost.
                border-top: $border;

                // Fix the left and right pixel.
                &:first-child {
                    border-left: $border;
                }
                &:last-child {
                    border-right: $border;
                }
            }

            .columnFiller:global(.sticky-top) {
                // Render under ticks to not obscure tick labels.
                z-index: $zindex-sticky - 1;
            }

            > * {
                background-color: $ticks-row-background;
            }
        }

        .ticks {
            display: flex;
            justify-content: space-between;
            box-sizing: content-box; // will accommodate the tick line height, we just need to give enough padding for the tick label
            // Enough to place ticks somewhat in the middle of the settings bar button's height,
            // and make some space between the buttons and the first UJ group name.
            padding-top: calc(#{$symmetric-bar-padding-y} + 1.3em);
            padding-bottom: .3em;

            grid-column-start: bar-start;
            grid-column-end: bar-end;
            @include barColumn();

            > div {
                width: 0;
                // the tick line
                border-left: 1px solid black;
                height: 1ex;

                > div {
                    width: fit-content; // otherwise it's 0 because of the parent
                    white-space: pre; // no wrapping
                    text-align: center; // so it's centered over the tick line

                    position: relative;
                    transform: translateX(-50%) translateY(-100%);
                }
            }
        }

        .userJourneyGroup {
            @include rowContainer();
        }

        .groupName {
            grid-column-start: start;
            grid-column-end: end;
            // Gap takes care of spacing.
            margin-top: 0;
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;

            > :first-child {
                font-weight: 700;
            }
        }

        .showHideButton {
            --bs-btn-font-weight: 700;

            // Make the button text align with the page side margin.
            &:last-child {
                padding-right: 0;
            }
        }

        .userJourneyRow {
            @include rowContainer();

            .bar {
                @include barColumn();
            }

            > * {
                box-sizing: content-box;
                height: $user-journey-row-height;
                padding-top: calc($row-gap / 2);
                padding-bottom: calc($row-gap / 2);
            }

            &:hover > * {
                background: $table-hover-bg;
            }

            @mixin flash($flash-name) {
                animation-name: #{$flash-name}-flash-on,
                    #{$flash-name}-flash-off;
                animation-duration: 2s, 10s;
                animation-timing-function: ease-in, ease-out;
            }

            @mixin flash-keyframes($flash-name, $flash-color) {
                @keyframes #{$flash-name}-flash-on {
                    from {
                        background-color: inherit;
                    }
                    to {
                        background-color: lighten($flash-color, 15%);
                    }
                }

                @keyframes #{$flash-name}-flash-off {
                    from {
                        background-color: lighten($flash-color, 15%);
                    }
                    to {
                        background-color: inherit;
                    }
                }
            }

            @include flash-keyframes("ok", $status-ok-color);
            @include flash-keyframes("error", $status-error-color);
            @include flash-keyframes("warning", $status-warning-color);
            @include flash-keyframes("slow", $status-slow-color);
            @include flash-keyframes("debug", $status-debug-color);

            &.flashOK > * {
                @include flash("ok");
            }
            &.flashERROR > * {
                @include flash("error");
            }
            &.flashWARNING > * {
                @include flash("warning");
            }
            &.flashSLOW > * {
                @include flash("slow");
            }
            &.flashDEBUG > * {
                @include flash("debug");
            }

            // UJ link
            > a:first-child {
                white-space: nowrap;
                line-height: $user-journey-row-height;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            // latest sample link or placeholder
            > a:last-child,
            > div:last-child {
                display: flex;

                span {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    color: $inverted-text-color;
                    align-items: center;
                    justify-content: center;
                }
            }

            &[data-hasdata="false"] {
                .bar > span {
                    display: flex;
                    height: 100%;
                    background-color: $status-bar-bg-color;
                }

                div:last-child > span {
                    background-color: $status-bar-bg-color;
                }
            }
        }

        * {
            align-self: center;
        }
    }
}
