@import "src/variables";

section.heatTiles {
    ul {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            width: 20%;
            text-align: center;
            border: 1px solid $border-color;

            &.ERROR {
                background-color: rgba($status-error-color, 0.6);
            }

            &.SLOW {
                background-color: rgba($status-slow-color, 0.6);
            }

            a {
                display: block;
                text-decoration: none;
                color: $text-color;

                .target {
                    font-weight: 600;
                }
            }
        }
    }
}

.changeTileDisplayButtonRow {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}
