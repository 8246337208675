@import "src/variables";

.graphTypeSelect {
    width: auto;
}

.card {
    border: 1px solid $border-color;
}

.chartPlaceholderContainer {
    padding: 20px 80px 70px;
}

.chartPlaceholder {
    height: 100%;
    background-color: #bbbbbb;
    font-size: 150px;
    color: white;
    text-align: center;
    border: 1px solid $border-color;
}

.loadingIndicator {
    // Align the loading indicator to the right corner
    margin-left: auto;
}

.graphTypeSelector {
    display: inline-block;
}
