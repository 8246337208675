@import "src/variables";
@import "src/global";

$flex-gap: 2px;

.header {
    padding: 20px $page-side-margin 20px $page-side-margin;
    border-bottom: 1px solid $border-color;
    background-color: white;

    .logo {
        width: 80px;
        height: 80px;
        margin-right: 20px;
    }

    @include media-breakpoint-up(lg) {
        .logo {
            margin-right: 50px;
        }
    }

    .leftNav {
        .ujPageMenu {
            margin-right: 1px;
        }
    }

    @include media-breakpoint-down(lg) {
        // Force a wrap after the overview menu
        // It's possible for a client view and a short UJ name to still fit all three menus
        // in a row, but now it is aligned to the top of the header, it looks bad without a wrap.
        .overviewMenu {
            flex-basis: 100%;
        }
    }

    @include media-breakpoint-up(lg) {
        .leftNav {
            font-size: larger;

            :global(.dropdown-item) {
                font-size: larger;
            }

            :global(.btn) {
                // font-size larger isn't consistent, it's a magic thing,
                // but we want this to be the same size as the other font-size larger.
                font-size: 16.8px;
            }
        }
    }

    // Don't style hav links as links, so they look consistent with the rest of the header nav.
    :global(.nav-item):not(:global(.show)) {
        :global(.nav-link):not(:global(.active)):not(:global(.active)):not(:global(:hover)) {
            color: $text-color;
            font-weight: inherit;
        }
    }

    .user {
        :global(a) {
            font-weight: 600;
        }
        // Make this line up with the overview menu on medium width screens
        // The nav has 5px padding and 1px of margin above and below
        // Plus a flex gap
        padding-top: 6px;
        padding-bottom: 6px;
        margin-bottom: $flex-gap;
    }

    :global(.nav) {
        :global(.nav-item) a,
        :global(.dropdown) :global(.btn) {
            &:hover {
                @include buttonTextOnHover;
                @include activeItem($primary);
            }
        }

        :global(.nav-item) {
            &:not(:global(.dropdown)) {
                // Buttons which are not navigation buttons, but are in the navigation bar
                :global(.btn):hover {
                    @include buttonTextOnHover;
                }
            }
            :global(.nav-link),
            :global(.btn) {
                padding: $nav-item-padding;
            }
            :global(.btn) {
                font-weight: normal;
                color: $text-color;
                background-color: $header-background-color;
                border: 0;
                box-shadow: none;
                margin: 0;
                // When the user has selected an option from this menu, show the inverted style.
                &:global(.active) {
                    @include activeItem($primary);
                }
            }
            &:global(.show) {
                // When the user opens the menu, show the inverted style
                :global(.btn) {
                    @include activeItem($primary);
                }
            }
        }
    }
}

.placeholderSeparator {
    padding-left: 2px;
    padding-right: 2px;
}

.userItemPlaceholder {
    font-size: larger;
}

.flexGap {
    gap: $flex-gap 5px;
}

.customerName {
    font-style: italic;
    padding: $nav-item-padding;
}

.copyIcon {
    margin-left: 1px;
}

:global(.dropdown-menu) {
    // Ensures opened dropdowns render on top of the wallboard ticks and settings bar button.
    z-index: $zindex-sticky + 2;
}
