.filter {
    margin-top: -0.5rem; // Make it go right at the top of the menu
    margin-bottom: 0.25rem; // leave a gap to the rest of the items
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;

    &:focus {
        box-shadow: none;
    }
}

.highlightUJID {
    font-weight: bold;
}

.dropdownMenu {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
}
